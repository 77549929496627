<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">

      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{name: 'company.dashboard'}">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t('question.questions') }}</li>
            <li>{{ $t('question.question_list') }}</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span> {{ $t('question.question_list') }} </span></h2>
        </div>
        <div class="section-header-right">
          <router-link :to="{name:'company.question.add'}">
            <button class="btn btn-default" type="button" aria-expanded="false"><i class="uil-plus"></i> {{$t('question.add')}}</button>
          </router-link>
        </div>
      </div>
      <div class="card" v-if="loading">
        <default-loading></default-loading>
      </div>
      <div class="card" v-else>
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <div>
              <a @click="filterResult" href="javascript:void(0)" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop uk-open"  style="left: 91px; top: 159.297px;">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" @change="filterResult" v-model="filter.search" :placeholder="$t('general.search')" autofocus="">
                </span>
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('name','asc')">{{ $t('filters.order.a_z') }}</a></li>
                  <li><a href="javascript:void(0)" @click="sortOrder('name','desc')">{{ $t('filters.order.z_a') }}</a></li>
                  <li><a href="javascript:void(0)" @click="sortOrder('created_at','asc')"> {{ $t('filters.order.created_at_asc') }}</a></li>
                  <li><a href="javascript:void(0)"  @click="sortOrder('created_at','desc')"> {{ $t('filters.order.created_at_desc') }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">

          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{$t('question.attributes.text')}}</th>
              <th scope="col">{{$t('question.attributes.type')}}</th>
              <th scope="col">{{$t('general.created_at')}}</th>
              <th scope="col"> </th>
            </tr>
            </thead>
            <tbody class="list">
            <!--user item-->
            <tr v-for="item in formattedItem">
              <td>{{item.text}}</td>
              <td><i :class="item.question_type_icon"></i> {{item.question_type}}</td>
              <td> {{ item.created_at }}</td>
              <td class="text-right">
                <div class="actions ml-3">
                  <router-link :to="{name:'company.question.edit',params :{id:item.id}}" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.edit')" title="" aria-expanded="false">
                    <i class="uil-edit"></i>
                  </router-link>
                  <a v-if="item.status" @click="statusChange(item.id,0)" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.do_passive')" title="" aria-expanded="false">
                    <i class="uil-minus-circle text-danger"></i>
                  </a>
                  <a v-else @click="statusChange(item.id,1)" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.do_active')" title="" aria-expanded="false">
                    <i class="uil-check text-success"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination :pagination="pagination" @changePage="setPage($event)"></pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import module, { BASE_URL, GET_ITEMS, MODULE_NAME, ERROR, ITEMS, UPDATE_ITEM, LOADING, SUCCESS } from "@/core/services/store/question.module";
import Pagination from "@/assets/components/Pagination";
import store from "@/core/services";
import moment from "moment";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

export default {
  name: "QuestionList",
  components: {Pagination, DefaultLoading},
  beforeCreate () {
    function registerStoreModule (moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  data() {
    return {
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      filter: {
        search : null,
        orderBy: null,
        sort: null
      },
      pagination : null
    }
  },

  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + '/' + ITEMS]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    success() {
      return store.getters[MODULE_NAME + '/' + SUCCESS]
    },
    loading(){
      return store.getters[MODULE_NAME + '/' + LOADING]
    },
    formattedItem(){
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];

      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        this.pagination = this.items;
        if (currentItem) {
          results.push({
            id: currentItem.id,
            text: currentItem.text,
            type: currentItem.type,
            status: currentItem.status,
            created_at: currentItem.created_at ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm:ss') : '-',
            question_type: currentItem.question_type.name,
            question_type_icon: currentItem.question_type.icon,
          });
        }
      }
      return results;
    },
  },
  methods: {
    statusChange(id,status_id){
      let formData = new FormData();
      formData.append('question',id);
      formData.append('status',status_id);

      store.dispatch(MODULE_NAME + '/' + UPDATE_ITEM, {
        url: BASE_URL + '/status/' + id,
        contents: formData,
        returnType: "stateless",
        acceptPromise: true,
      })
    },
    filterResult() {
      this.$store.dispatch(MODULE_NAME + '/' + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: this.filter.search,
          page: this.page,
          per_page: this.per_page,
          orderBy: this.filter.orderBy,
          sort: this.filter.sort,
        }
      });
    },
    sortOrder(orderBy,sort){
      this.page = 1;
      this.filter.orderBy = orderBy;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page){
      this.page = page;
      this.filterResult()
    }
  },
  mounted() {
    this.filterResult();
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult()
      }
    },
  },
};
</script>
<style scoped>
input[type="button"], input[type="submit"], span.btn, .btn{
  display: inline-block;
}
</style>
